import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import '../styles/globals.css';

const MyApp = ({ Component, pageProps }: AppProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
  const SafeHydrate = dynamic(() => import('../components/SafeHydrate'), {
    ssr: false,
  });

  return (
    <>
      {/* Add `<Head></Head>` here if you want to inject elements into <head>. */}
      <SafeHydrate>
        <Component {...pageProps} />
      </SafeHydrate>
    </>
  );
};

export default MyApp;
